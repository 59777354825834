import googleIcon from "@iconify-icons/fa-brands/google";
import yelpIcon from "@iconify-icons/fa-brands/yelp";
import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import md5 from "md5";
import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Link as RouterLink,
  Switch,
} from "react-router-dom";

import awsconfig from "./aws-exports";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfUse from "./screens/TermsOfUse";

Amplify.configure(awsconfig);

const theme = createMuiTheme({
  palette: {
    background: {
      default: "#ffb3ab",
    },
    primary: {
      light: "#ffe5dd",
      main: "#ffb3ab",
      dark: "#ca837c",
      contrastText: "#000",
    },
    secondary: {
      light: "#484d51",
      main: "#212529",
      dark: "#000000",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles({
  root: {
    margin: theme.spacing(2),
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card: {
    margin: 4,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cta: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    margin: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  paragraph: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  image: {
    width: "80%",
    maxWidth: 400,
    margin: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  flexCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 1,
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  footer: {
    bottom: 0,
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.secondary.light,
    },
    "&:link": {
      color: theme.palette.secondary.main,
    },
    "&:visited": {
      color: theme.palette.secondary.main,
    },
    marginRight: theme.spacing(1),
  },
});

const App = () => {
  const [store, setStore] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);
  const [firstname, setFirstName] = React.useState();
  const [lastname, setLastName] = React.useState();
  const [email, setEmail] = React.useState();
  const [emailValidationFailed, setEmailValidationFailed] =
    React.useState(false);
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleStoreChange = (event) => {
    setStore(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    handleEmailValidation(event);
    setEmail(event.target.value);
  };

  const handleEmailValidation = (event) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailValidationFailed(
      !re.test(String(event.target.value).toLowerCase())
    );
  };

  function getSteps() {
    return ["Select a store", "Leave us a review"];
  }

  const getReviewUrl = (store, platform) => {
    const mapping = {
      google: {
        anderson: "https://g.page/pokey-okey-anderson/review?rc",
        kingsway: "https://g.page/pokey-okey-kingsway/review?gm",
        hornby: "https://g.page/pokey-okey-hornby/review?gm",
      },
      yelp: {
        anderson:
          "https://www.yelp.ca/writeareview/biz/Wq86vRzPZ2DsYkN38HnUQA?return_url=%2Fbiz%2FWq86vRzPZ2DsYkN38HnUQA&source=biz_details_war_button",
        kingsway:
          "https://www.yelp.ca/writeareview/biz/Spk82trTvJ1ZbCaN3F7Z4Q?return_url=%2Fbiz%2FSpk82trTvJ1ZbCaN3F7Z4Q&source=biz_details_war_button",
        hornby:
          "https://www.yelp.ca/writeareview/biz/2uVKoiTIT7LGD40QQgY3vA?return_url=%2Fbiz%2F2uVKoiTIT7LGD40QQgY3vA&source=biz_details_war_button",
      },
    };
    return mapping[platform][store];
  };

  const handleSubmit = async (platform) => {
    try {
      let hash = md5(
        firstname.toLowerCase() + lastname.toLowerCase() + store + email
      );
      let operation = `mutation addRecord {
        createReviewEntryStaging(input: {entry_id: "${hash}", firstname: "${firstname}", lastname: "${lastname}", store: "${store}", platform: "${platform}", email: "${email}", ttl: ${
        (new Date().getTime() / 1000).toFixed(0) + 24 * 60 * 60 * 1000
      }}) {
        entry_id
        }
      }`;
      await API.graphql(graphqlOperation(operation));
    } catch (err) {
      console.error(err);
    } finally {
      window.location = getReviewUrl(store, platform);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <FormControl component="fieldset">
              <FormLabel color="secondary" className={classes.paragraph}>
                Your most recent visit to Pokey Okey was at:
              </FormLabel>
              <RadioGroup
                color="secondary"
                value={store}
                onChange={handleStoreChange}
              >
                <FormControlLabel
                  value="anderson"
                  control={<Radio />}
                  label="8060 Anderson Road, Richmond BC"
                />
                <FormControlLabel
                  value="kingsway"
                  control={<Radio />}
                  label="4919 Kingsway, Burnaby BC"
                />
                <FormControlLabel
                  value="hornby"
                  control={<Radio />}
                  label="995 Hornby Street, Vancouver BC"
                />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Typography className={classes.paragraph}>
              Before you leave your review, we need some information to verify
              your contribution and to send your coupon!
            </Typography>
            <Typography color="error" variant="h5" component="p" align="center">
              VERY IMPORTANT
            </Typography>
            <Typography color="error" variant="h6" component="p" align="center">
              <strong>
                We need the name that appears when you post your review!!
              </strong>
            </Typography>
            <Typography className={classes.paragraph}>
              If you need help with that, you can read more below:
            </Typography>
            <Accordion
              expanded={expanded === "google"}
              onChange={handleExpand("google")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  What is my name on Google reviews?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Click
                  <Link
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/contrib/",
                        "_blank"
                      )
                    }
                    rel="noreferrer"
                  >
                    <strong>{` here `}</strong>
                  </Link>
                  to view your Google contributions (you may be required to
                  sign-in). From here you will be able to see your name on your
                  Google contributions.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "yelp"}
              onChange={handleExpand("yelp")}
              className={classes.accordion}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  What is my name on Yelp reviews?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Click
                  <Link
                    href="#"
                    onClick={() =>
                      window.open("https://www.yelp.ca/user_details", "_blank")
                    }
                    rel="noreferrer"
                  >
                    <strong>{` here `}</strong>
                  </Link>
                  to view your Yelp profile (you may be required to sign-in).
                  From here you will be able to see your name on your Yelp
                  reviews.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Typography className={classes.paragraph}>
              Fill out the required information below. We will not use this
              information for any other purpose than to verify your review
              submission. Once your submission has been verified, all personal
              information will no longer be retained.
            </Typography>

            <Grid container direction="column">
              <Grid>
                <TextField
                  error={!firstname}
                  className={classes.paragraph}
                  required
                  id="firstname"
                  label="First Name (required)"
                  helperText="Please enter your first name"
                  placeholder="ex. John"
                  variant="outlined"
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid>
                <TextField
                  error={!lastname}
                  className={classes.paragraph}
                  required
                  id="lastname"
                  label="Last Name (required)"
                  helperText="Please enter your last name"
                  placeholder="ex. Doe"
                  variant="outlined"
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid>
                <TextField
                  id="email"
                  error={emailValidationFailed || !email}
                  className={classes.paragraph}
                  required
                  label="E-mail (required)"
                  helperText="Please enter a valid email address that you would like your coupon to be sent"
                  placeholder="ex. name@example.com"
                  variant="outlined"
                  onChange={handleEmailChange}
                />
              </Grid>
            </Grid>

            <Typography className={classes.paragraph}>
              <strong>
                Next, use one of the buttons below to submit your review. Please
                make sure the name you have entered above matches the one that
                will appear on the platform that you will leave the review.
              </strong>
            </Typography>

            <Typography className={classes.paragraph}>
              Once you have posted your review, we will require some time to
              verify your submission. This will typically be completed within 24
              hours. You should receive an email with your coupon once the
              review has been verified! If you have submitted a review and have
              not received a coupon, please email support@pokeyokey.com for
              assistance.
            </Typography>

            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs>
                <Button
                  disabled={
                    !firstname ||
                    !email ||
                    emailValidationFailed ||
                    !store ||
                    !lastname
                  }
                  className={classes.cta}
                  startIcon={<Icon icon={googleIcon} />}
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit("google")}
                >
                  Leave a review on Google
                </Button>
              </Grid>
              <Grid item xs>
                <Typography variant="h6" component="p" align="center">
                  <strong>OR</strong>
                </Typography>
              </Grid>
              <Grid item xs>
                <Button
                  disabled={
                    !firstname ||
                    !email ||
                    emailValidationFailed ||
                    !store ||
                    !lastname
                  }
                  align="center"
                  className={classes.cta}
                  startIcon={<Icon icon={yelpIcon} />}
                  color="primary"
                  variant="contained"
                  onClick={() => handleSubmit("yelp")}
                >
                  Leave a review on Yelp
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route path="/terms">
            <TermsOfUse />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route path="/">
            <Container className={classes.root}>
              <Card className={classes.card}>
                <CardContent>
                  <CardHeader
                    title="Your recent experience at Pokey Okey"
                    subheader="Let us know how we did and get rewarded with a $5 coupon!"
                  />

                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                          {getStepContent(index)}
                          <div className={classes.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.button}
                                variant="outlined"
                              >
                                Back
                              </Button>
                              {activeStep === steps.length - 1 ? null : (
                                <Button
                                  disabled={activeStep === 0 ? !store : false}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleNext}
                                  className={classes.button}
                                >
                                  Next
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </CardContent>
              </Card>
            </Container>
          </Route>
        </Switch>
        <Container className={classes.footer}>
          <RouterLink to="/" className={classes.link} color="secondary">
            Home
          </RouterLink>
          <RouterLink to="/terms" className={classes.link} color="secondary">
            Terms of Use
          </RouterLink>
          <RouterLink to="/privacy" className={classes.link} color="secondary">
            Privacy Policy
          </RouterLink>
          <Typography variant="caption" component="p">
            © Copyright 2021 TinyTuna Solutions Inc.
          </Typography>
        </Container>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;

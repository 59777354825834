/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "reviewSubmissions-staging",
            "region": "us-west-2"
        },
        {
            "tableName": "reviewEntry-staging",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2y5nqmxk55dvfiqm5dtqdmoe2i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7nyscqcsijbk3gd2sbvljz77ra"
};


export default awsmobile;
